import React from "react";
import i18n from "i18n-react";
import {  Row, Col, Table } from 'reactstrap';
import SelectGIE from '../../components/SelectGIE';
import * as FaIcons from "react-icons/fa";
import {stopPropagation} from "../../components/SidebarFunctions";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import DefaultModal from "../../components/DefaultModal";
import AddContent from "../../img/add_content.png";
import RemoveContent from "../../img/remove_content.png";
import DefaultModalError from "../../components/DefaultModalError";
import { catchErrorAjax, calculateScroll, toastFunction } from "../../components/mainFunctions"; 
import { DefaultAjax } from "../../components/DefaultAjax";
import DefaultSpinner from "../../components/DefaultSpinner";

export const url = process.env.REACT_APP_API_URL;

class Management extends React.Component {
    constructor(props){
        super(props);
        this.state={
            modalContent:"",
            modalContent2:"",
            spinner:"",
            selectOrgType:"",
            priceForFilter:50,
            packList: [],
            packListForFilter: [], 
            unassignedList: [],
            assignedList: [],
            usersToAddRemove: [],
            currentPack:""  
        }
    }
    
    componentDidMount(){
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>
        });
        calculateScroll(30);
        this.getPacks();
    }

    getPacks = () => {
        DefaultAjax('').get("/fePyUsedDocument/fePyExtraDocumentPacks")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-3 col-lg-3 col-xl-3 selectGIE"} classSelectType={"col-sm-12 col-md-3 col-lg-3 col-xl-3 displayNone"}/>,
                    packList: result,
                    packListForFilter: result,
                    spinner: ""
                })
                
                setTimeout(() => {
                    const inputElement = document.querySelector("#userSelectedDescription");
                    if (inputElement) {
                        inputElement.addEventListener('input', (event) => { 
                            this.searchPacks(event, 'searchForUsers'); 
                        });
                    }
                }, 10);
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 
    
    clearFilters = (e) => {
        document.querySelector("#searchOnline").value = "";
        e.target.value = "";
        this.setState({
            selectOrgType: <SelectGIE classSelect={"col-sm-12 col-md-3 col-lg-3 col-xl-3 selectGIE"} classSelectType={"col-sm-12 col-md-3 col-lg-3 col-xl-3 displayNone"}/>,
            priceForFilter:50
        })
        this.searchPacks(e, '');
    } 

    confirmPacksUserAssign = () => {
        this.setState({modalContent2:""});
        setTimeout(() => {
            let content = this.packAssignmentSummaryContent();
            let buttons = [{text: i18n.translate("default_confirm"), function: this.executeAssignPackToUsers},
            {text: i18n.translate("cancel"), function: ""}]  
            this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("summary_of_changes")}
            content={content} buttons={buttons} />}) 
        }, 10);
    }

    executeAssignPackToUsers = () => {
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            modalContent2:"",
        });
        DefaultAjax('').post("/fePyUsedDocument/fePyExtraDocumentPackPrinter",this.state.usersToAddRemove)
        .then((res) => {
            if (res) {
                const result = JSON.parse(res.data);
                this.setState({ spinner: "", modalContent:"" });
                if (result === 0 || result === "0") {
                    toastFunction(i18n.translate("users_successfully_assigned"), "success");
                    this.getPacks();
                    this.clearFilters();
                } else {
                    setTimeout(() => {
                        this.setState({
                            modalContent2: <DefaultModalError errorList={result.errorsList} status={result} />,
                            isEdit: true
                        });
                    }, 10);
                }
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    } 

    packAssignmentSummaryContent = () => {
        const usersToAdd = this.state.usersToAddRemove.filter(user => user.addOrRemove === 'add');
        const usersToRemove = this.state.usersToAddRemove.filter(user => user.addOrRemove === 'remove');

        const countAdded = usersToAdd.length;
        const countRemoved = usersToRemove.length;
        const totalPackValue = parseFloat(this.state.currentPack.price * countAdded) + '$';

        const message = i18n.translate("pack_changes_message", { 
            countAdded, 
            countRemoved, 
            totalToPay: totalPackValue 
        });

        return (
            <div>
                <p dangerouslySetInnerHTML={{ __html: message }} />
                
                {countAdded > 0 && (
                    <div>
                        <strong>{i18n.translate("users_to_add")}:</strong>
                        <ul>
                            {usersToAdd.map(user => (
                                <li key={user.username}>
                                    {user.username} - {user.fullname} 
                                    {user.extraPackId !== 0 && user.extraPackId !== "" && user.extraPackId !== null && 
                                        <span className="bolder"> ({i18n.translate("current_pack")}: {user.extraPackName})</span>
                                    }    
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                
                {countRemoved > 0 && (
                    <div>
                        <strong>{i18n.translate("users_to_delete")}:</strong>
                        <ul>
                            {usersToRemove.map(user => (
                                <li key={user.username}>
                                    {user.username} - {user.fullname} 
                                    {user.extraPackId !== 0 && user.extraPackId !== "" && user.extraPackId !== null && 
                                        <span className="bolder"> ({i18n.translate("current_pack")}: {user.extraPackName})</span>
                                    }    
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        );
    } 

    handleUpdateUsersInPack = (action, user) => {
        this.setState((prevState) => {
            let unassignedList = [...prevState.unassignedList];
            let assignedList = [...prevState.assignedList];
            let usersToAddRemove = [...prevState.usersToAddRemove];
    
            if (action === 'add') {
                const userIndex = usersToAddRemove.indexOf(user);
                if (userIndex !== -1) {
                    usersToAddRemove.splice(userIndex, 1);
                } else {
                    user.addOrRemove = 'add';
                    user.addExtraDocumentPackId = prevState.currentPack.id;
                    user.removeExtraDocumentPackId = user.extraPackId; 
                    usersToAddRemove.push(user);
                }
                assignedList.push(user);
                unassignedList = unassignedList.filter(u => u.username !== user.username);
            } else {
                const userIndex = usersToAddRemove.indexOf(user);
                if (userIndex !== -1) {
                    usersToAddRemove.splice(userIndex, 1);
                } else {
                    user.addOrRemove = 'remove';
                    user.addExtraDocumentPackId = prevState.currentPack.id;
                    user.removeExtraDocumentPackId = user.extraPackId;
                    usersToAddRemove.push(user);
                }
    
                unassignedList.push(user);
                assignedList = assignedList.filter(u => u.username !== user.username);
            }
    
            return {
                unassignedList,
                assignedList,
                usersToAddRemove,
                unassignedListFiltered: unassignedList,
                assignedListFiltered: assignedList,
            };
        }, this.updateModalContent);
    }

    updateUsersInPack = (action,user) => {
        if(action === 'add' && user?.packAssignedReplicaId){
            this.showModalToConfirm(action,user);
        }else{
            this.handleUpdateUsersInPack(action,user);
        }  
    } 

    searchAssignedUnAssignedList = (e, listToSearch) => {
        const searchTerm = e.target.value.toLowerCase();
    
        this.setState((prevState) => {
            const filteredList = prevState[listToSearch].filter(item => {
                const { username, fullname, extraPackName } = item;
    
                // Filtramos basándonos en los campos requeridos
                const isExtraPackValid = extraPackName && extraPackName !== '0';
                const matchesUsername = username && username.toLowerCase().includes(searchTerm);
                const matchesFullname = fullname && fullname.toLowerCase().includes(searchTerm);
                const matchesExtraPack = isExtraPackValid && extraPackName.toLowerCase().includes(searchTerm);
    
                // Retornamos true si coincide en alguno de los campos
                return matchesUsername || matchesFullname || matchesExtraPack;
            });
    
            return {
                [`${listToSearch}Filtered`]: filteredList // Guardamos la lista filtrada en el estado
            };
        },this.updateModalContent);
    }
     

    showModalToConfirm = (action,user) => {
        this.setState({modalContent2:""});
        setTimeout(() => {
            let content = (<div dangerouslySetInnerHTML={{ __html: i18n.translate("modify_current_pack_description", { currentPack: "<span class='bolder'>"+user.extraPackName+"</span>" }) }} />);
            let buttons = [{text: i18n.translate("default_confirm"), function: ()=>{this.handleUpdateUsersInPack(action,user); this.setState({modalContent2:""});}},
            {text: i18n.translate("cancel"), function: ""}]  
            this.setState({modalContent2:<DefaultModal show={true} title={i18n.translate("modify_current_pack")}
            content={content} buttons={buttons} />}) 
        }, 10);
    } 
    
    contenForManagePacks = () => {
        const { unassignedListFiltered, assignedListFiltered } = this.state;
        return(
            <Row>
                <Col>
                    <div>
                        <input placeholder="Buscar no asignados" type="text" 
                        className="form-control inputStandard" onChange={(e)=> {this.searchAssignedUnAssignedList(e,'unassignedList')}}/>
                    </div>
                    <div>
                        {unassignedListFiltered.map((assigned) => {
                            return(
                                <div key={assigned.username} 
                                style={{borderBottom: '1px solid',display:'inline-flex',width: '100%'}}>
                                    <div style={{width:'80%'}}>
                                        <div>
                                            {assigned.username} - {assigned.fullname}
                                        </div>
                                        <div>
                                            {assigned.branch} - {assigned.printer}
                                        </div>
                                        <div>
                                            {assigned.extraPackName} 
                                        </div>
                                        <div>
                                            <span className="bolder">{i18n.translate("default_used")}: </span>{assigned.usedQty} 
                                            <span className="bolder"> {i18n.translate("default_of")}: </span>{assigned.totalQty} 
                                        </div>
                                    </div>
                                    <div style={{width:'20%',margin:'auto'}}>
                                        <img className='addContent' onClick={()=> this.updateUsersInPack('add',assigned)} 
                                        src={AddContent} style={{marginLeft: '25px'}} width="20px" height="20px"/>
                                    </div>
                                </div>
                            )
                        })} 
                    </div>
                </Col>
                <Col>
                    <div>
                        <input placeholder="Buscar asignados" type="text" 
                        className="form-control inputStandard" onChange={(e)=> {this.searchAssignedUnAssignedList(e,'assignedList')}}/>
                    </div>
                    <div>
                        {assignedListFiltered.map((unassigned) => {
                            return(
                                <div key={unassigned.username} 
                                style={{borderBottom: '1px solid',display:'inline-flex',width: '100%'}}>
                                    <div style={{width:'80%'}}>
                                        <div>
                                            {unassigned.username} - {unassigned.fullname}
                                        </div>
                                        <div>
                                            {unassigned.branch} - {unassigned.printer}
                                        </div>
                                        <div>
                                            {unassigned.extraPackName} 
                                        </div>
                                        <div>
                                            <span className="bolder">{i18n.translate("default_used")}: </span>{unassigned.usedQty} 
                                            <span className="bolder"> {i18n.translate("default_of")}: </span>{unassigned.totalQty} 
                                        </div>
                                    </div>
                                    <div style={{width:'20%',margin:'auto'}}>
                                        <img className='addContent' onClick={()=> this.updateUsersInPack('remove',unassigned)} 
                                        src={RemoveContent} style={{marginLeft: '25px'}} width="20px" height="20px"/>
                                    </div>
                                </div>
                            )
                        })} 
                    </div>
                </Col>
            </Row>
        )
    } 

    showPack = (pack) => {
        this.setState({
            spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").value}/>,
            currentPack:pack
        });
        DefaultAjax('').get("/fePyUsedDocument/fePyExtraDocumentPackPrinter?extraDocumentPackId="+pack.id)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({
                    unassignedList: result.unassignedList,
                    unassignedListFiltered: result.unassignedList,
                    assignedList: result.assignedList,
                    assignedListFiltered: result.assignedList,
                    modalContent:"",
                    spinner: ""
                })
        
                this.updateModalContent();
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
    }

    updateModalContent = () => {
        setTimeout(() => {
            let content = this.contenForManagePacks()
            let buttons = [{text: i18n.translate("save"), function: this.confirmPacksUserAssign},
            {text: i18n.translate("cancel"), function: ""}]  
            this.setState({modalContent:<DefaultModal show={true} title={i18n.translate("package_management")}
            content={content} buttons={buttons} size={'lg'} />}) 
        }, 10);
    } 
    
    showUsers = (e,id) => {
        stopPropagation(e);
        if(document.querySelector("#arrowDown-"+id).style.display === 'none'){
            document.querySelector("#arrowDown-"+id).style.display = 'initial';
            document.querySelector("#arrowRight-"+id).style.display = 'none';
            if(document.querySelector("#usersContainer-"+id)){
                document.querySelector("#usersContainer-"+id).style.display = 'block';
            } 
        }else{
            document.querySelector("#arrowDown-"+id).style.display = 'none';
            document.querySelector("#arrowRight-"+id).style.display = 'initial';
            if(document.querySelector("#usersContainer-"+id)){
                document.querySelector("#usersContainer-"+id).style.display = 'none';
            } 
        }  
    }
    
    searchPacks = (e, searchType) => {
        let searchTerm;
        if(searchType === 'searchForPrice'){
            searchTerm = parseFloat(e.target.value);
            this.setState({
                priceForFilter: e.target.value
            })
        }else{
            searchTerm = e.target.value.toLowerCase(); 
        }  
        
        this.setState((prevState) => {
            const filteredList = prevState.packList.filter(item => {
                const { packName, packDescription } = item;
        
                // Convertir searchTerm a número si es posible
                const priceTerm = parseFloat(searchTerm);
                const isPriceSearch = !isNaN(priceTerm);
        
                // Lógica de filtrado
                const matchesPackName = !isPriceSearch && packName?.toLowerCase().includes(searchTerm.toLowerCase());
                const matchesPackDescription = !isPriceSearch && packDescription?.toLowerCase().includes(searchTerm.toLowerCase());
                
                // Filtrado específico por precio
                const matchesPrice = isPriceSearch && item.price && parseFloat(item.price) <= priceTerm;
        
                let matchesUser;
                // Filtrado por usersList
                if (searchType === 'searchForUsers' && searchTerm === 'todos') {
                    matchesUser = true;
                } else if(!isPriceSearch) {
                    matchesUser = item.usersList?.some(user => {
                        return (user.fullname + ' - ' + user.username).toLowerCase() === searchTerm.toLowerCase();
                    });
                }
        
                // Filtramos solo si es búsqueda por precio, de lo contrario, consideramos otros campos
                return isPriceSearch ? matchesPrice : (matchesPackName || matchesPackDescription || matchesUser);
            });
        
            return {
                packListForFilter: filteredList // Guardamos la lista filtrada en el estado
            };
        });
        
    } 

    render(){
        return(
            <>
                {this.state.modalContent}
                {this.state.modalContent2}
                <nav className='navbar'>
                    <Row style={{ width:'100%'}}>
                        <Col xs={12} md={3} lg={3} xl={3}>
                            <label style={{display: "block"}}>
                                <span style={{visibility:'hidden'}}>{i18n.translate("default_search")}</span>
                            </label>
                            <input type="text" id="searchOnline" className="form-control inputStandard" 
                            onChange={(e)=> {this.searchPacks(e,'searchOnline')}}/>
                        </Col>
                        {this.state.selectOrgType}
                        <Col xs={12} md={3} lg={3} xl={3}> 
                            <label style={{display: "block"}}>{i18n.translate("storage_price")}</label>
                            <input type="range" min="0" max="50" value={this.state.priceForFilter} 
                            onChange={(e)=> {this.searchPacks(e,'searchForPrice')}}/>
                            <p>{i18n.translate("current_value")}: {this.state.priceForFilter}</p>
                        </Col>
                        <Col xs={12} md={3} lg={3} xl={3} style={{ paddingTop: "22px", textAlign: 'center' }}>
                            <button className='buttonMzateSecondary' onClick={(e)=> this.clearFilters(e)}>
                                <FaIcons.FaSearch />
                                <span>{i18n.translate("default_show_all")}</span>
                            </button>
                        </Col>
                    </Row> 
                </nav>
                <br/>
                <div className='bodyContent' id='bodyContent'>
                    {this.state.spinner}
                    <Table className='table table-hover'>
                        <tbody>
                            <tr>
                                <th className='firstcell'>Pack</th>
                                <th className='firstcell'>{i18n.translate("description")}</th>
                                <th className='firstcell'>{i18n.translate("storage_price")}</th>
                                <th className='firstcell'>{i18n.translate("default_users")}</th>
                            </tr>
                            {this.state.packListForFilter.length === 0 ? (
                                <tr>
                                    <td colSpan={10} style={{ textAlign: 'center' }}>
                                        {i18n.translate("there_are_no_results_for_the_search")}
                                    </td>
                                </tr>
                            ) : (
                                this.state.packListForFilter.map((pack) => (
                                    <tr key={pack.id} style={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => this.showPack(pack)}>
                                        <td>{pack.packName}</td>
                                        <td>{pack.packDescription}</td>
                                        <td>{pack.price} $</td>
                                        <td style={{ width: '25%' }}>
                                            <div onClick={(e) => this.showUsers(e, pack.id)}>
                                                <ReactTooltip id={`arrowInfo-${pack.id}`} place="top" effect="solid" />
                                                {pack.usersList.length}
                                                <FaIcons.FaChevronRight id={`arrowRight-${pack.id}`} data-tooltip-id={`arrowInfo-${pack.id}`} data-tooltip-content={i18n.translate("default_show")} />
                                                <FaIcons.FaChevronDown id={`arrowDown-${pack.id}`} style={{ display: 'none' }} />
                                            </div>
                                            {pack.usersList.length > 0 && (
                                                <div id={`usersContainer-${pack.id}`} style={{ borderWidth: '0 1px 1px 1px', 
                                                borderStyle: 'solid', borderColor: 'black', borderRadius: '5px', 
                                                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.5)', display: 'none' }}>
                                                    {pack.usersList.map((user) => (
                                                        <label key={user.username} style={{ display: 'block' }}>
                                                            {user.fullname} - {user.username}
                                                        </label>
                                                    ))}
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
            </>
        )
    } 
} 

export default Management;