import '../../App.css';
import React from 'react';
import i18n from "i18n-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { DescriptionConfig, DescriptionGIE, NewGIE } from './DescriptionConfig';


class DynamicInputValueTemplate extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            values: [],
            // valuesToUpdate: {},
            title:"title",
            form:props.array,
            detail: props.det
        } 

        if(!this.state.detail){
            let simulator = {};
            simulator.value = props.array.detail[0].value;
            this.state.detail = simulator;
        }

    }

    handleInputChange = (e) => {

        const fieldName = e.target.getAttribute('fieldname');
    
        if ( (fieldName === "notify" || fieldName === "can_add") ) {

            const numericInput = document.querySelector('input[fieldname="qty"]');
            const numericInputDiv = document.querySelector('.numericInputDiv');

            if(e.target.value === false || e.target.value === "false"){
                if (numericInput) {
                    numericInput.value = "0";
                    numericInputDiv.style.display = "none";
                }
            }else if (numericInput) {
                numericInputDiv.style.display = "";
                numericInput.value = "1";
            }

        }

    };

    render(){

        return(
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <DescriptionConfig keyValue={this.state.form.key}/>
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("current_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        <div>
                            <ul>
                                {this.state.detail.value.map((item, index) => (
                                    <li key={item.key}>
                                        <strong>
                                            {i18n.translate(item.key)}:
                                        </strong>{" "}
                                        {item.type === "boolean"? i18n.translate(item.value):item.value}
                                        <br />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                {this.state.form.update !== false &&
                    <DescriptionGIE type={this.state.detail.type}/>
                }
                {this.state.form.update === false && 
                    <NewGIE/>
                }
                
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("new_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        <div>
                            {this.state.detail.value.map((item, index) => {
                                if (item.type === "boolean") {
                                    return (
                                        <div key={item.key}>
                                            <b>{i18n.translate(item.key)}: </b>
                                                <select className="form-select selectStandard " defaultValue={item.value} fieldName = {item.key} fieldType={item.type} onChange={(e) => this.handleInputChange(e)} >
                                                    <option value="true">{i18n.translate("true")}</option>
                                                    <option value="false">{i18n.translate("false")}</option>
                                                </select>
                                            <br />
                                        </div>
                                    );
                                }
                                if (item.type === "numeric") {
                                    return (
                                        <div key={item.key} className='numericInputDiv' style={{ display: Number(item.value) === 0 ? "none" : "block" }} >
                                            <span>
                                                <b>{i18n.translate(item.key)}: </b>
                                                <input type="number" className="form-control inputStandard" fieldName = {item.key} fieldType={item.type} defaultValue={item.value} 
                                                    onChange={(e) => {
                                                        if (e.target.value < 1 && item.key ==="qty") {
                                                            e.target.value = 0;
                                                        }
                                                        this.handleInputChange(e);
                                                    }} />
                                                <br />
                                            </span>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    </div>
                </div>

            </div>



        )
    } 

}

export default DynamicInputValueTemplate;