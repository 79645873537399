import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import SelectGIE from '../../components/SelectGIE';

export const DescriptionConfig = (props) => {
    return(
        <div className='form-group row'>
            <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                <b>{i18n.translate("description")}: </b>
            </div>
            <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                {i18n.translate("Description"+props.keyValue)}
            </div>
        </div>
    )
}

export const DescriptionGIE = (props) => {
    return(
        <div className='form-group row'>
            <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                <b>{i18n.translate("default_type")}: </b>
            </div>
            <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                {props.type === 0 || props.type === "0" ? i18n.translate("default_predeterminated") : ''}
                {props.type === 1 || props.type === "1" ? i18n.translate("default_profile") : ''}
                {props.type === 2 || props.type === "2" ? i18n.translate("default_group") : ''}
                {props.type === 3 || props.type === "3" ? i18n.translate("default_user") : ''} 
            </div>
        </div>
    )
} 

export const NewGIE = () => {
    return(
        <div className='form-group row'>
            <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                <b>{i18n.translate("default_type")}: </b>
            </div>
            <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                <SelectGIE modal="true"/>
            </div>
        </div>
    )
}
