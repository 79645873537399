export const getDynamicInputValues = () => {

    const numericInput = document.querySelector('input[fieldname="qty"]');
    const selectElements = document.querySelectorAll('select[fieldname="notify"], select[fieldname="can_add"]');

    if (numericInput && selectElements.length > 0) {
        if (Number(numericInput.value) === 0) {
            selectElements.forEach((select) => {
                if (select.value === "true") {
                    select.value = "false";
                    select.dispatchEvent(new Event("change", { bubbles: true }));
                }
            });
        }
    }

    const elements = document.querySelectorAll('[fieldName]');

    const result = Array.from(elements).map(element => {
        return {
            key: element.getAttribute('fieldName'),
            value: element.value,
            type: element.getAttribute('fieldType')
        };
    });

    return result;
}