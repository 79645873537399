import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import RemoveContent from "../../img/remove_content.png";
import 'react-toastify/dist/ReactToastify.css';
import { DynamicInputValue, DynamicMap, IntervalDayAndHour } from "../templateConfigs/TemplateConfigs";
import BooleanTemplate from "../templateConfigs/BooleanTemplate";
import ArrayTemplate from "../templateConfigs/ArrayTemplate";
import DynamicMapTemplate from "../templateConfigs/DynamicMapTemplate";
import IntervalDayAndHourTemplate from '../templateConfigs/IntervalDayAndHourTemplate';
import ArrayOfMapTemplate from "../templateConfigs/ArrayOfMapTemplate";
import DynamicSelectedValuesTemplate from '../templateConfigs/DynamicSelectedValuesTemplate';
import DynamicInputValueTemplate from '../templateConfigs/DynamicInputValueTemplate';
import { toast } from 'react-toastify';
import { BooleanShow, ArrayStringShow, DynamicMapShow, IntervalDayAndHourShow, ArrayOfMapShow, DynamicSelectedValuesShow,DynamicInputValueShow } from '../templateConfigs/TemplateConfigs';
import i18n from "i18n-react";
import { getArrayOfMapValues } from './getArrayOfMap';
import { getDynamicInputValues } from './getDynamicInputValue';
import { getDynamicMapValues } from './getDynamicMapValues';
import { getIntervalDayHourValues } from './getIntervalDayHourValues';
import { DefaultAjax } from "../../components/DefaultAjax";
import { toastFunction, catchErrorAjax } from '../../components/mainFunctions'; 

const url = process.env.REACT_APP_API_URL;

class TemplateShowConfigs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: props.config,
            modalActualizar: false,
            modalEliminar:false,
            detailDetele: {},
            detailToUpdate: ""
        }
    }

    showToUpdate = (det) => {
        this.setState({detailToUpdate:det})
        this.state.config.update = false;
        let component = "";
        this.setState({ modalActualizar: true });
        this.state.config.create = true;
        if (det) {
            this.state.config.update = true;
        }
        if (this.state.config.companySettingType === "DYNAMIC_MAP") {
            this.setState({ htmlModal: <DynamicMapTemplate array={this.state.config} det={det}/> });
        } else if (this.state.config.companySettingType === "ARRAY_STRING") {
            this.setState({ htmlModal: <ArrayTemplate array={this.state.config} det={det}/> });
        } else if (this.state.config.companySettingType === "INTERVAL_DAY_AND_HOUR") {
            this.setState({ htmlModal: <IntervalDayAndHourTemplate array={this.state.config} det={det}/> });
            component = <IntervalDayAndHour array={this.state.config} />
        } else if (this.state.config.companySettingType === "BOOLEAN") {
            this.setState({ htmlModal: <BooleanTemplate array={this.state.config} det={det} /> });
        } else if(this.state.config.companySettingType === "ARRAY_OF_MAP"){
            this.setState({ htmlModal: <ArrayOfMapTemplate array={this.state.config} det={det} /> });
        }else if(this.state.config.companySettingType === "DYNAMIC_SELECTED_VALUES"){
            this.setState({htmlModal: <DynamicSelectedValuesTemplate array={this.state.config} det={det} />})
        }else if(this.state.config.companySettingType === "DYNAMIC_INPUT_VALUE"){
            this.setState({htmlModal: <DynamicInputValueTemplate array={this.state.config} det={det} /> })
        }
    }

    actualizarConfiguracion = () => {
        let configuration = {
            key: "",
            value: "",
            companySettingType: "",
            functionalityGroup: "",
            tenantId: "",
            id: "",
            orgNodeId: "",
            type: "",
            username: "",
            fullname: ""
        };
        const validSettingsType = ["BOOLEAN", "DYNAMIC_SELECTED_VALUES"];
        const isValid = validSettingsType.includes(this.state.config.companySettingType) 
        if (isValid){
            configuration.companySettingType = this.state.config.companySettingType;
            configuration.value = document.querySelector(".modalBody .newValue").value;
            configuration = this.getConfigsValues(configuration);
            this.executeUpdate(configuration);
        }else if (this.state.config.companySettingType === "ARRAY_STRING"){
            let valueString;
            configuration.value = [];
            let inputs = document.querySelectorAll(".arrayStringValue input");
            for(var i = 0; i < inputs.length; i++){
                configuration.value.push(inputs[i].value);
            }
            configuration.companySettingType = this.state.config.companySettingType;
            if(configuration.value.length > 0){
                valueString = JSON.stringify(configuration.value);
                configuration.value = valueString;
                configuration = this.getConfigsValues(configuration);
                this.executeUpdate(configuration);
            }else{
                this.showMessage();
            } 
        }else if(this.state.config.companySettingType === "DYNAMIC_MAP"){
            if(this.state.config.key === "PROFILES_THAT_CAN_CREATE_TASKS"){
                let valueString,valor;
                configuration.value = {
                    "ion_level_1":{
                        "value":"false","type":"boolean"
                    },
                    "ion_level_2":{
                        "value":"false","type":"boolean"
                    },
                    "ion_level_3":{
                        "value":"false","type":"boolean"
                    },
                    "ion_level_4":{
                        "value":"false","type":"boolean"
                    },
                    "ion_level_5":{
                        "value":"false","type":"boolean"
                    }
                };
                valor = configuration.value;
                let selects = document.querySelectorAll(".dynamicMapValue select");
                for(var i = 0; i < selects.length; i++){
                    Object.keys(valor).forEach((e) => {
                        if(e === selects[i].id){
                            valor[e].value = selects[i].value;
                        }
                    });
                }
                configuration.companySettingType = this.state.config.companySettingType;
                valueString = JSON.stringify(configuration.value);
                configuration.value = valueString;
                configuration = this.getConfigsValues(configuration);
                this.executeUpdate(configuration);
            }else if(this.state.config.key === "RESCHEDULE_TASK"){
                let valueString,valor;
                configuration.value = {
                    "allowsRescheduling":{
                        "value":"false","type":"BOOLEAN"
                    },
                    "daysToAddWhenRescheduling":{
                        "value":"10","type":"int"
                    },
                    "tasks_to_do":{
                        "value":["Revisar","Presupuestar","Mantenimiento","Entrega"],"type":"ARRAY_STRING"
                    }
                };
                valor = configuration.value;
                valor = getDynamicMapValues(valor);

                configuration.companySettingType = this.state.config.companySettingType;
                valueString = JSON.stringify(valor);
                configuration.value = valueString;
                configuration = this.getConfigsValues(configuration);
                this.executeUpdate(configuration);
            }
        }else if(this.state.config.companySettingType === "INTERVAL_DAY_AND_HOUR"){
            configuration.value = "";
            let val = getIntervalDayHourValues();
            configuration.companySettingType = this.state.config.companySettingType;
            if(val === ""){
                this.showMessage();
            }else{
                configuration.value = val;
                configuration = this.getConfigsValues(configuration);
                this.executeUpdate(configuration);
            } 
        }else if(this.state.config.companySettingType === "ARRAY_OF_MAP"){
            let values = getArrayOfMapValues(); 
            if(values.length > 0){
                configuration.value = JSON.stringify(values);
                configuration = this.getConfigsValues(configuration);
                this.executeUpdate(configuration);
            }else{
                this.showMessage();
            } 
        } else if(this.state.config.companySettingType === "DYNAMIC_INPUT_VALUE"){
            
            configuration = this.getConfigsValues(configuration);

            let result = getDynamicInputValues();
            configuration.value = JSON.stringify(result);

            this.executeUpdate(configuration);

        }
        
    }

    getConfigsValues = (configuration) =>{
        configuration.companySettingType = this.state.config.companySettingType;
        configuration.key = this.state.config.key;
        configuration.functionalityGroup = this.state.config.functionalityGroup;
        configuration.id = this.state.detailToUpdate.id;
        configuration.tenantId = this.state.detailToUpdate.tenantId;
        configuration.orgNodeId = this.state.detailToUpdate.assignedId;
        configuration.type = this.state.detailToUpdate.type;
        configuration.username = "username";
        configuration.fullname = "fullname";
        return configuration;
    }

    executeUpdate = (configuration) => {
        DefaultAjax('').put("companySetting/companySetting",configuration)
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result === 0 || result === "0"){
                    toast.success(i18n.translate("configuration_modified"), {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
                    this.state.config.show = false;
                    {this.state.config.detail.map((detalle)=>{
                        if(detalle.id == this.state.detailToUpdate.id){
                            detalle.value = configuration.value
                        }
                    })}
                    this.setState({modalActualizar: false});
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        });
 
    }

    cerrarModalActualizar = () => {
        this.setState({ modalActualizar: false });
    }

    cerrarModalEliminar = () => {
        this.setState({ modalEliminar: false })
    }

    removeConfig = (detail,event) => {
        let configuration = {
            id: detail.id,
            orgNodeId: detail.assignedId,
            type: detail.type,
            tenantId: detail.tenantId,
            key: this.state.config.key,
            functionalityGroup:this.state.config.functionalityGroup,
            value: "",
            username: "username",
            fullname: "fullName"
        }
        {this.state.config.detail.map((det)=>{
            if(det.id === detail.id){
                configuration.value = JSON.stringify(det.value)
            }
        })}
        this.setState({detailDetele:configuration})
        event.stopPropagation();
        this.setState({modalEliminar:true});
    }

    executeDetele = () =>{
        let configuration = this.state.detailDetele;
        let detail = [];

        DefaultAjax('').delete("companySetting/companySetting",{data: configuration})
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                if(result === 0 || result === "0"){
                    toastFunction(i18n.translate("configuration_deleted"),"success");
                    this.setState({modalEliminar:false});
                    {this.state.config.detail.map((det)=>{
                        if(det.id != configuration.id){
                            detail.push(det)
                        }
                    })}
                    this.state.config.detail = detail;
                }else{
                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");
                }
            }
        }).catch((error) =>{
            catchErrorAjax(error, this.setState.bind(this));
        }); 
    }

    showMessage = () =>{
        toastFunction(i18n.translate("add_configuration"),"error");
    } 

    render(){
        return(
            <>
            <Table className='table table-hover divIndexTable'>
                <tr>
                    <th colspan='2'>
                        {i18n.translate(this.state.config.key)}
                    </th>
                    <th class='thDescription' rowspan={this.state.config.detail.length + 1}>
                        {i18n.translate("Description"+this.state.config.key)}
                    </th>
                </tr>
                {this.state.config.detail.map((det)=>{
                    return(
                        <tr className='cellOptions' onClick={() => this.showToUpdate(det)}>
                            <td>
                                {det.type !== 0 && 
                                <img class='removeContent' width="35px"  onClick={(event) => this.removeConfig(det,event)} src={RemoveContent}/>}
                                {det.type === 0 && 
                                    <>
                                    <b>{i18n.translate("default_predeterminated")}: </b>
                                    {i18n.translate("default_predeterminated")}
                                    </>
                                    
                                }
                                {det.type === 1 && 
                                    <>
                                        <b>{i18n.translate("default_profile")}: </b>
                                        {det.description}
                                    </>
                                }
                                {det.type === 2 && 
                                    <>
                                        <b>{i18n.translate("default_group")}: </b>
                                        {det.description}
                                    </>
                                }
                                {det.type === 3 && 
                                    <>
                                    <b>{i18n.translate("default_user")}: </b>
                                    {det.description}
                                    </>
                                }
                            </td>
                            <td className ='cellValue'>
                                {this.state.config.show = true}
                                {this.state.config.companySettingType === "DYNAMIC_MAP" && <DynamicMapShow array={det} />}
                                {this.state.config.companySettingType === "ARRAY_STRING" && <ArrayStringShow array={det} />}
                                {this.state.config.companySettingType === "INTERVAL_DAY_AND_HOUR" && <IntervalDayAndHourShow array={det} />}
                                {this.state.config.companySettingType === "BOOLEAN" && <BooleanShow array={det} />}
                                {this.state.config.companySettingType === "ARRAY_OF_MAP" && <ArrayOfMapShow array={det} />}
                                {this.state.config.companySettingType === "DYNAMIC_SELECTED_VALUES" && <DynamicSelectedValuesShow array={det} />} 
                                {this.state.config.companySettingType === "SELECTED_VALUES" && <ArrayStringShow array={det} />} 
                                {this.state.config.companySettingType === "DYNAMIC_INPUT_VALUE" &&  <DynamicInputValueShow array={det} />} 
                            </td>
                        </tr>
                    )
                })}
            </Table>
            <Modal isOpen={this.state.modalActualizar}>
                <ModalHeader className='modalHeader'>
                    {i18n.translate("updateConfiguration")}
                    <button className='close' data-dismiss="close" onClick={() => this.cerrarModalActualizar()}>x</button>
                </ModalHeader>
                <ModalBody className='modalBody' >
                    {this.state.htmlModal}
                </ModalBody>
                <ModalFooter>
                    <button className="buttonMzatePrimary" onClick={() => this.actualizarConfiguracion()}>{i18n.translate("update")}</button>
                    <button className="buttonMzateSecondary" onClick={() => this.cerrarModalActualizar()}>{i18n.translate("cancel")}</button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={this.state.modalEliminar}>
            <ModalHeader className='modalHeader'>
                {i18n.translate("deleteConfiguration")} 
                <button className='close' data-dismiss="close" onClick={()=>this.cerrarModalEliminar()}>x</button>
            </ModalHeader>
            <ModalBody className='modalBody'>
                <FormGroup>
                    <label>{i18n.translate("delete_confirm_configuration")}</label>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <button className="buttonMzatePrimary" onClick={()=>this.executeDetele()}>{i18n.translate("delete")}</button>
                <button className="buttonMzateSecondary" onClick={()=>this.cerrarModalEliminar()}>{i18n.translate("cancel")}</button>
            </ModalFooter>
            </Modal>
            </>
        )
        
    }
}

export default TemplateShowConfigs;