import '../../App.css';
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import RemoveContent from "../../img/remove_content.png";
import AddContent from "../../img/add_content.png";
import 'bootstrap/dist/css/bootstrap.min.css';
import i18n from "i18n-react";
import * as FaIcons from "react-icons/fa";
import { DescriptionConfig, DescriptionGIE, NewGIE } from './DescriptionConfig';

const url = process.env.REACT_APP_API_URL;

class ArrayOfMapTemplate extends React.Component {
    constructor(props){
        let count = 1;
        let values = [];
        let valuesToUpdate = [];
        let valuesToShow = [];
        super(props);
        this.state = {
            values: [],
            valuesToUpdate: [],
            valuesToShow: [], 
            form:props.array,
            detail: props.det
        }  
        if(this.state.detail){
            values = this.state.detail.value;
            values = JSON.parse(values);
             
        }else{
            {this.state.form.detail.map((elem) =>{
                if(elem.type == 0){
                    values = elem.value;
                    values = JSON.parse(values);
                }
             })}
        }
        {values.map((v) =>{
            valuesToShow.push({"type":count+" - "+v.type,
            "settings":{"required_end_photo":{"value":v.settings.required_end_photo.value,"type":"BOOLEAN"},
            "required_signature":{"value":v.settings.required_signature.value,"type":"BOOLEAN"},
            "required_start_photo":{"value":v.settings.required_start_photo.value,"type":"BOOLEAN"},
            "standard_duration":{"value":v.settings.standard_duration.value,"type":"numeric"},
            "tasks_to_do":{"value":v.settings.tasks_to_do.value,"type":"ARRAY_STRING"}}});
            valuesToUpdate.push({"type":v.type,
            "settings":{"required_end_photo":{"value":v.settings.required_end_photo.value,"type":"BOOLEAN"},
            "required_signature":{"value":v.settings.required_signature.value,"type":"BOOLEAN"},
            "required_start_photo":{"value":v.settings.required_start_photo.value,"type":"BOOLEAN"},
            "standard_duration":{"value":v.settings.standard_duration.value,"type":"numeric"},
            "tasks_to_do":{"value":v.settings.tasks_to_do.value,"type":"ARRAY_STRING"}}});
            count++;
        })}
        this.state.valuesToUpdate =  valuesToUpdate;
        this.state.valuesToShow =  valuesToShow;
    }

    showDetailConfig = (i) => {
        let div = document.querySelectorAll(".detailConfig");
		for(let j = 0; j < div.length; j++){
            if(div[j].id === document.querySelector("#detailConfig-"+i).id){
                if(document.querySelector("#detailConfig-"+i).style.display === "none"){
                    document.querySelector("#detailConfig-"+i).style.display = "";
                    document.querySelector("#iconPlus-"+i).style.display = "none";
                    document.querySelector("#iconMinus-"+i).style.display = "";
                }else{
                    document.querySelector("#detailConfig-"+i).style.display = "none";
                    document.querySelector("#iconPlus-"+i).style.display = "";
                    document.querySelector("#iconMinus-"+i).style.display = "none";
                } 
            }else{
                div[j].style.display = "none";
                document.querySelector("#iconPlus-"+j).style.display = "";
                document.querySelector("#iconMinus-"+j).style.display = "none";
            } 
        } 
    }
    handleChange = (e,i) =>{
        let newValues = []; 
        newValues = this.state.valuesToUpdate;
        if(e.target.classList.contains("configType")){
            newValues[i].type =  e.target.value;
        }else if(e.target.classList.contains("standard_duration")){
            newValues[i].settings.standard_duration.value = e.target.value;
        }else if(e.target.classList.contains("taskToDo")){
            let newValuesArrayString = [];
            let inputs = document.querySelectorAll(".taskToDo-"+i);
            let arrayInput = Array.from(inputs);
            arrayInput.map((s) => newValuesArrayString.push(s.value));
            newValues[i].settings.tasks_to_do.value = newValuesArrayString;
        }
        this.setState({valuesToUpdate:newValues});
    }

    removeOptionTaskToDo = (j,i) => {
        let newValues = []; 
        let array = []; 
        newValues = this.state.valuesToUpdate;
        array = newValues[i].settings.tasks_to_do.value;
        array.splice(j,1)
        newValues[i].settings.tasks_to_do.value = array;
        this.setState({valuesToUpdate:newValues});
    }
    
    addOptionTaskToDo = (i) => {
        let newValues = [];
        let array = [];
        newValues = this.state.valuesToUpdate;
        array = newValues[i].settings.tasks_to_do.value;
        array.push("");
        newValues[i].settings.tasks_to_do.value = array;
        this.setState({valuesToUpdate:newValues});
    } 

    addOption = () => {
        let array = this.state.valuesToUpdate;
        let newValues = {"type":"","settings": {"required_end_photo":{"value":"true","type":"BOOLEAN"},
        "required_signature":{"value":"true","type":"BOOLEAN"},
        "required_start_photo":{"value":"true","type":"BOOLEAN"},
        "standard_duration":{"value":"60","type":"numeric"},
        "tasks_to_do":{"value":["","","","",],"type":"ARRAY_STRING"}}} 
        array.push(newValues)
        this.setState({valuesToUpdate:array});
    }
    
    removeOption = (i) => {
        let newValues = []; 
        newValues = this.state.valuesToUpdate;
        newValues.splice(i,1);
        this.setState({valuesToUpdate:newValues});
    } 

    render(){
        return(
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <DescriptionConfig keyValue={this.state.form.key}/>
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3'>
                        <b>{i18n.translate("current_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        {this.state.valuesToShow.map((det,i) =>{
                           return(
                            <>
                                <b style={{paddingRight:'16px'}}>{det.type}</b>
                                <FaIcons.FaPlusCircle id={'iconPlus-'+i} onClick={() => this.showDetailConfig(i)} style={{cursor:'pointer',color: '#001188'}}/>
                                <FaIcons.FaMinusCircle style={{display:'none',cursor:'pointer',color: '#001188'}}  id={'iconMinus-'+i} onClick={() => this.showDetailConfig(i)}/>
                                <br/>
                                <div className={'detailConfig'} id={'detailConfig-'+i}  style={{display:'none'}}>
                                    {det.settings.required_start_photo && 
                                    <>
                                        <span>
                                            <b>{i18n.translate("required_start_photo")}: </b>
                                            {i18n.translate(det.settings.required_start_photo.value)} 
                                            <br/>
                                        </span>
                                    </>
                                    } 
                                    {det.settings.required_end_photo && 
                                    <>
                                        <span>
                                            <b>{i18n.translate("required_end_photo")}: </b>
                                            {i18n.translate(det.settings.required_end_photo.value)} 
                                            <br/>
                                        </span>
                                    </>
                                    }
                                    {det.settings.required_signature && 
                                    <>
                                        <span>
                                            <b>{i18n.translate("required_signature")}: </b>
                                            {i18n.translate(det.settings.required_signature.value)} 
                                            <br/>
                                        </span>
                                    </>
                                    }
                                    {det.settings.standard_duration && 
                                    <>
                                        <span>
                                            <b>{i18n.translate("standard_duration")}: </b>
                                            {i18n.translate(det.settings.standard_duration.value)} {i18n.translate("default_minutes")} 
                                            <br/>
                                        </span>
                                    </>
                                    }
                                    {det.settings.tasks_to_do && 
                                    <>
                                        <span>
                                            <b>{i18n.translate("tasks_to_do")}: </b>
                                            {det.settings.tasks_to_do.value.map((tasks, j) =>{
                                                return(
                                                    <>
                                                        {j != 0 && ", "} 
                                                        {tasks}
                                                    </>
                                                )
                                            })} 
                                            <br/>
                                        </span>
                                    </>
                                    }
                                </div>
                            </>
                            )
                        })} 
                    </div>
                </div>
                {this.state.form.update !== false &&
                    <DescriptionGIE type={this.state.detail.type}/>
                }
                {this.state.form.update === false && 
                    <NewGIE/>
                }
                <div className='form-group row'>
                    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3' style={{fontSize: '11px;'}}>
                        <b>{i18n.translate("new_value")}: </b>
                    </div>
                    <div className='col-sm-9 col-md-9 col-lg-9 col-xl-9'>
                        {this.state.valuesToUpdate.map((valuesUpdate,i) =>{
                            return(
                                <div className={'divTaskType divTaskType-'+i}>
                                    <div style={{display:'flex'}}>
                                        <img onClick={() => this.removeOption(i)} className='removeContent' src={RemoveContent} width="20px" height="20px"/>
                                        <input onChange={(e) => this.handleChange(e,i)} value={valuesUpdate.type} className='form-control inputStandard configType'/>
                                    </div>
                                    <br/>
                                    {valuesUpdate.settings.required_start_photo && 
                                    <div style={{paddingLeft:'8%'}}>
                                        <b>{i18n.translate("required_start_photo")}: </b>
                                        <select class='form-select selectStandard required_start_photo'>
                                            {valuesUpdate.settings.required_start_photo.value == "true" ?  <option value="true" selected>{i18n.translate("true")}</option> : <option value="true">{i18n.translate("true")}</option>}
                                            {valuesUpdate.settings.required_start_photo.value == "false" ?  <option value="false" selected>{i18n.translate("false")}</option> : <option value="false">{i18n.translate("false")}</option>}
                                        </select>
                                        <br/>
                                    </div>
                                    }
                                    {valuesUpdate.settings.required_end_photo && 
                                    <div style={{paddingLeft:'8%'}}>
                                        <b>{i18n.translate("required_end_photo")}: </b>
                                        <select class='form-select selectStandard required_end_photo'>
                                            {valuesUpdate.settings.required_end_photo.value == "true" ?  <option value="true" selected>{i18n.translate("true")}</option> : <option value="true">{i18n.translate("true")}</option>}
                                            {valuesUpdate.settings.required_end_photo.value == "false" ?  <option value="false" selected>{i18n.translate("false")}</option> : <option value="false">{i18n.translate("false")}</option>}
                                        </select>
                                        <br/>
                                    </div>
                                    }
                                    {valuesUpdate.settings.required_signature && 
                                    <div style={{paddingLeft:'8%'}}>
                                        <b>{i18n.translate("required_signature")}: </b>
                                        <select class='form-select selectStandard required_signature'>
                                            {valuesUpdate.settings.required_signature.value == "true" ?  <option value="true" selected>{i18n.translate("true")}</option> : <option value="true">{i18n.translate("true")}</option>}
                                            {valuesUpdate.settings.required_signature.value == "false" ?  <option value="false" selected>{i18n.translate("false")}</option> : <option value="false">{i18n.translate("false")}</option>}
                                        </select>
                                        <br/>
                                    </div>
                                    }
                                    {valuesUpdate.settings.standard_duration && 
                                    <div style={{paddingLeft:'8%'}}>
                                        <span>
                                            <b>{i18n.translate("standard_duration")}: </b>
                                            <input type="number" onChange={(e) => this.handleChange(e,i)} class='form-control inputStandard standard_duration' value={valuesUpdate.settings.standard_duration.value}/>
                                            <br/>
                                        </span>
                                    </div>
                                    }
                                    {valuesUpdate.settings.tasks_to_do && 
                                    <div style={{paddingLeft:'8%'}}>
                                            <b>{i18n.translate("tasks_to_do")}: </b>
                                            <img onClick={() => this.addOptionTaskToDo(i)} className='addContent' src={AddContent} width="20px" height="20px"/>
                                            {valuesUpdate.settings.tasks_to_do.value.map((tasks, j) =>{
                                                return(
                                                    <div class='arrayStringValue'>
                                                        <img onClick={() => this.removeOptionTaskToDo(j,i)} className='removeContent' src={RemoveContent} width="20px" height="20px"/>
                                                        <input value={tasks} class={'form-control inputStandard taskToDo taskToDo-'+i} onChange={(e) => this.handleChange(e,i)} type='text'/>
                                                    </div>
                                                )
                                            })} 
                                            <br/>
                                            <br/>
                                    </div>
                                    }
                                </div>
                            )
                        })} 
                        <div>
                            <img onClick={this.addOption} className='addContent' src={AddContent} width="20px" height="20px"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    } 
}  

export default ArrayOfMapTemplate;