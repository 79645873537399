import React from "react";
import i18n from "i18n-react";
import {  Row, Col } from 'reactstrap';
import { toastFunction } from "../../components/mainFunctions";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DefaultSpinner from '../../components/DefaultSpinner';
import { DefaultAjax, modalForbidden } from "../../components/DefaultAjax";
import * as FaIcons from "react-icons/fa";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const url = process.env.REACT_APP_API_URL;
class ImportProducts extends React.Component{
    constructor(props){
        super(props);
        this.state={
            fileUploadState:"",
            importsAuditList: [],
            spinner: "",
            hasError : false
        }
        this.inputReference = React.createRef(); 
    }

    componentDidMount() {
        let nav = document.querySelector("#nav-top").offsetHeight;
        let altura = window.innerHeight - nav - 90;
        let bodyContent = document.querySelector("#bodyContent");
        bodyContent.style.maxHeight = altura + "px";
        this.getImportsAudit();
    }

    fileUploadAction = () =>this.inputReference.current.click();

    fileUploadInputChange = (e) => {
        this.setState({fileUploadState:e.target.value});
    } 

    getImportsAudit = () => {

        this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").values}/>})

        DefaultAjax('').get("fePyImportProduct/index")
        .then((res) => {
            if(res){
                let result = JSON.parse(res.data);
                this.setState({spinner:"",importsAuditList:result.auditList,hasError:result.hasError});
            }
        }).catch((error) =>{
            this.setState({
                modalContent:"",
                spinner:"",
                component:error.message
            });
            if (error.response) {
                if(error.response.status === 403){
                    setTimeout(()=> { 
                        this.setState({modalContent:modalForbidden()});
                    }, 10);
                } 
            }
        });
    }  

    importFile = () => {
        if(document.querySelector("#importFile").files[0]){
            this.setState({spinner:<DefaultSpinner instanceType={document.querySelector("#instanceType").value} 
            independent={document.querySelector("#independent").values}/>})
            let fd = new FormData();
		    fd.append('importFile',document.querySelector("#importFile").files[0]);
            DefaultAjax('').post("fePyImportProduct/import",fd)
            .then((res) => {
                if(res){
                    let result = JSON.parse(res.data);
                    if(result.status === "-1" || result.status === -1){
                        toastFunction(result.message,"warning");
                        document.querySelector("#importFile").value = "";
                        this.setState({spinner:"",fileUploadState:""});
                        return;
                    }else{
                        toastFunction(result.message,"success");
                        this.setState({spinner:"",fileUploadState:""});
                        document.querySelector("#importFile").value = "";
                        this.getImportsAudit();
                        return;
                    }
                }
            }).catch((error) =>{
                this.setState({
                    modalContent:"",
                    spinner:"",
                    component:error.message
                });
                document.querySelector("#importFile").value = "";
                if (error.response) {
                    if(error.response.status === 403){
                        setTimeout(()=> { 
                            this.setState({modalContent:modalForbidden()});
                        }, 10);
                    } 
                }
            });
        }else{
            toastFunction(i18n.translate("import_file_null"),"warning");
        }  
    } 

    exportModel = async () => {

        DefaultAjax('', 'blob').get(url+"fePyImportProduct/exportModel")
            .then((res) => {
                if(res.status === 200){

                    const FileDownload = require('js-file-download');

                    toastFunction(i18n.translate("download_started"),"success");

                    FileDownload(res.data, 'Archivo_de_importacion_productos(Modelo).xlsx');

                }else{

                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");   

                }
            }).catch((error) =>{
                console.error('Error al descargar el archivo:', error);
            }); 

    };

    exportError  = async (id) => {

        const idValue = id.id ? id.id : id;

        DefaultAjax('', 'blob').get(url+"fePyImportProduct/exportError?id="+idValue)
            .then((res) => {

                if(res.status === 200){

                    const FileDownload = require('js-file-download');

                    toastFunction(i18n.translate("download_started"),"success");

                    FileDownload(res.data, 'Corregir_Errores.xlsx');

                }else{

                    toastFunction(i18n.translate("an_error_has_ocurred"),"error");   

                }

            }).catch((error) =>{
                console.error('Error al descargar el archivo de error:', error);
            }); 

    };

    getClassName(index, listLength) {
        if (index === 0) {
            return "firstcell text";
        } else if (index === listLength - 1) {
            return "lastCell text";
        } else {
            return "cellgroup text";
        }
    }

    addRequiredsFields = () => {

        const requiredFieldsList = [
            { id: "productCodeText", i18nCode: "default_code" },
            { id: "productDescriptionText", i18nCode: "description" },
            { id: "productPriceText", i18nCode: "storage_price" },
            { id: "productTaxText", i18nCode: "tax_value" },
            { id: "typeOfProductText", i18nCode: "type_of_product" },
            { id: "unitOfMeasurementText", i18nCode: "unit_of_measurement" }
          ];

        return (
            <>
            {requiredFieldsList.map((item, index) => (
                <td className={index === 0 ? "firstcell text" : "cellgroup text"} key={item.id}>
                     {i18n.translate(item.i18nCode)} 
                </td>
            ))}
            </>
        );
    };

    addKeyAndValue = ({ lista, fieldType }) => {
        return (
        <>
            {lista.map((item, index) => (
            <tr key={item.value} className={`displayNone fieldType${fieldType}`}>
                <td className="firstcell text">
                    {i18n.translate(item.key)}
                </td>
                <td className="cellgroup text">
                    {item.value}
                </td>
            </tr>
            ))}
        </>
        );
    };

    unitOfMeasurementList = [
        { key: "Costo Por Mil (CPM)", value: 2366 },
        { key: "Unidad Internacional (UI)", value: 2329 },
        { key: "Metros cúbicos (M3)", value: 110 },
        { key: "Unidad (UNI)", value: 77 },
        { key: "Gramos (g)", value: 86 },
        { key: "Litros (LT)", value: 89 },
        { key: "Miligramos (MG)", value: 90 },
        { key: "Centímetros (CM)", value: 91 },
        { key: "Centímetros cuadrados (CM2)", value: 92 },
        { key: "Metros (m)", value: 87 },
        { key: "Centímetros", value: 93 }
      ].sort((a, b) => a.codigo - b.codigo);
    
    typeOfProductsList = [
    { key: "default_service", value: "Servicio" },
    { key: "default_product", value: "Producto" }
    ];
    
    taxList = [
        { key: "invoice_exempt", value: 0 },
        { key: "invoice_vat_5", value: 5 },
        { key: "invoice_vat_10", value: 10 }
    ];

    addTitle = ({ title, fieldType }) => {
        return (
          <>
            <tr onClick={() => showOrHideSpecialFields({ fieldType })}>
              <td className="firstcell text title cellOptions" colSpan={2}>
                <ReactTooltip id={`arrowInfo`} place="top" effect="solid" />
                <FaIcons.FaChevronRight id={`arrowRight-${fieldType}`} data-tooltip-id={`arrowInfo`} data-tooltip-content={i18n.translate("default_show")}/>
                <FaIcons.FaChevronDown id={`arrowDown-${fieldType}`} className="displayNone" />
                    {i18n.translate(title)}
              </td>
            </tr>
            <tr className={`displayNone fieldType${fieldType}`}>
              <td className="firstcell cellTitle">
                {i18n.translate("default_value")}
              </td>
              <td className="cellgroup cellTitle">
                {i18n.translate("representation")}
              </td>
            </tr>
          </>
        );
      };

      addAuditTile = () => {

        const auditTitleList = ["start_date", "end_date", "username", "fullname", "state", "file_name", "default_results","export_error"];

        return (
            <>
              {auditTitleList.map((item, index) => {
                if (index === auditTitleList.length - 1 && !this.state.hasError) {
                  return null;
                }
          
                return (
                  <th className={this.getClassName(index, auditTitleList.length)} key={item}>
                    {i18n.translate(item)}
                  </th>
                );
              })}
            </>
          );
          
    };

    addAudit = () => {

        return (

            <>

                {this.state.importsAuditList.map((item) => (

                    <tr key={item.id}>

                        <td className="firstcell text">{item.startDate}</td>
                        <td className="cellgroup text">{item.endDate}</td>
                        <td className="cellgroup text">{item.username}</td>
                        <td className="cellgroup text">{item.fullname}</td>
                        <td className="cellgroup text">{item.status}</td>
                        <td className="cellgroup text">{item.fileName}</td>
                        <td className="cellgroup text">
                            <ul style={{ minWidth: "170px", paddingLeft: "15px" }}>
                                {item.results?.map((result, index) => (
                                    <li key={item.id}>{result}</li>
                                ))}
                            </ul>
                        </td>

                        {this.state.hasError && (
                            <td className="cellgroup text vertical-align-middle">
                                {item.errorBytes && (
                                    <button className="buttonMzateSecondary" onClick={() => this.exportError({ id: item.id })}>
                                        {i18n.translate("export_error")}
                                    </button>
                                )}
                            </td>
                        )}

                    </tr>

                ))}

            </>
        )

    }

    render(){
        
        return(
            <>
                <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover></ToastContainer>
                <Row style={{borderBottom: '1px solid #DCDCDC',padding: '12px'}}>
                    {this.state.spinner}
                    <Col className="col-sm-12 col-md-9 col-lg-7 col-xl-7">
                        <label style={{marginRight:'10px'}}>
                            {i18n.translate("attach_the_import_file")}:
                        </label>
                        <input type="file" hidden ref={this.inputReference} onChange={this.fileUploadInputChange} id="importFile" 
                            accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excels"/>
                        <button className="ui button buttonMzateSecondary" onClick={this.fileUploadAction}>
                            {i18n.translate("select_file")} 
                        </button>
                        {this.state.fileUploadState}
                    </Col>
                    <Col className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-1" onClick={() => this.importFile()}>
                        <button className='buttonMzateSecondary'>{i18n.translate("default_import")}</button>
                    </Col>
                    <Col className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" onClick={() => this.exportModel()}>
                        <button className='buttonMzateSecondary'>{i18n.translate("export_model")}</button>
                    </Col>
                </Row>
                <div className='bodyContent' id='bodyContent'>
                    <Row>
                        <Col xl={12}>
                            <table className="table ">
                                <thead>
									<tr>
									    <th className="firstcell cellTitle" colSpan={12}>
                                            {i18n.translate("required_fields")} 
                                        </th>
									</tr>
								</thead>
                                <tbody>
                                    <tr>
                                        {this.addRequiredsFields()}
                                    </tr>
								</tbody>
                            </table>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12}>
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th className="firstcell cellTitle" colSpan={2}>
                                            {i18n.translate("special_fields")} 
                                        </th>
									</tr>
                                </thead>
                                <tbody>
                                    
                                    {this.addTitle({ title: "tax_value", fieldType: '0' })}
                                    {this.addKeyAndValue({ lista: this.taxList, fieldType: '0' })}
                                    
                                    {this.addTitle({ title: "default_product", fieldType: '1' })}
                                    {this.addKeyAndValue({ lista: this.typeOfProductsList, fieldType: '1' })}

                                    {this.addTitle({ title: "unit_of_measurement", fieldType: '2' })}
                                    {this.addKeyAndValue({ lista: this.unitOfMeasurementList, fieldType: '2' })}
                                    
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <div className="result table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    {this.addAuditTile()}
                                </tr>
                            </thead>
                            <tbody>
                               {this.addAudit()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    } 

}

function showOrHideSpecialFields({ fieldType }){
    
    const elementos = document.querySelectorAll(`.fieldType${fieldType}`);
    elementos.forEach((elemento) => {
      elemento.classList.toggle('displayNone');
    });

    const arrowDown = document.querySelector(`#arrowDown-${fieldType}`);
    const arrowRight = document.querySelector(`#arrowRight-${fieldType}`);

    arrowDown.classList.toggle('displayNone');
    arrowRight.classList.toggle('displayNone');

};


export default ImportProducts;